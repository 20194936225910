import React from "react";
import "./footer.scss";
import IconWhatsApp from '../../assets/images/icons/icon-whatsapp.png';

const whatsappBtn = (
    <a href="https://api.whatsapp.com/send?phone=5511975123488" className="icon_whatsapp_bottom round" target="_blank" rel="noreferrer">
        <img src={IconWhatsApp} alt="" />
    </a>
)


const Footer = () =>
    <footer>
        (c) Footer
        {whatsappBtn}
    </footer>;


export default Footer;
