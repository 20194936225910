import React from "react";
import IconLocalization from "../../assets/images/icons/IconLocalization";
import IconTime from "../../assets/images/icons/IconTime";
import IconWhatsApp from "../../assets/images/icons/IconWhatsapp";
import Title from "../../components/Title";
import "./Home.scss";

// Images
import blogIcon from '../../assets/images/icons/blog.png';
import EcommerceIcon from '../../assets/images/icons/compras-online.png';
import SEOIcon from '../../assets/images/icons/iconSeo.png';

export type HeaderData = {
  height: string;
};

const MainHome = (header: HeaderData) => {
  const [dataForm, setDataForm] = React.useState({
    name: "",
    subject: "",
    message: "",
  });

  const HandleClick = (e: any) => {
    e.preventDefault();
    return (
      window.open(
        `https://api.whatsapp.com/send?phone=5511975123488&text=${encodeURIComponent(
          `*Meu nome:* ${dataForm.name}\n*Assunto:* ${dataForm.subject}. \n*Mensagem:*\n${dataForm.message}`
        )}`,
        "_blank",
        "toolbar=0,location=0,menubar=0"
      ),
      setDataForm({
        name: "",
        subject: "",
        message: "",
      })
    );
  };

  return (
    <main className="mainContent flex flex-wrap justify-center">
      <section
        className="mainSection flex-wrap flex justify-center items-center ph-4 relative"
        style={{ height: `calc(100vh - ${header.height})` }}
      >
        <div className="center-content pa-4 relative">
          <div className="borderDecorationTop borderPrimaryColor"></div>
          <div className="borderDecorationBottom borderPrimaryColor"></div>
          <span className="mainSection--preTitle primaryColor">
            Olá, eu sou o
          </span>
          <h1 className="mainSection--title textPrimaryColor">
            Vinícius Alarcon -
            <div className="secondColor">
              Desenvolvedor VTEX, Shopify, ReactJs e{" "}
              <span className="primaryColor">designer.</span>
            </div>
          </h1>
          <p className="mainSection--paragraph secondColor">
            Desenvolvimento, consultoria e projeção em e-commerce, institucional
            e blog com foco em{" "}
            <span className="primaryColor">acessibilidade e performance</span>{" "}
            com as tecnologias mais recentes do mercado!
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=5511975123488"
            className="mainSection--button primaryColor"
            target="_blank"
            rel="noreferrer"
          >
            Entre em contato
          </a>
          <a
            href="#parardedaravisonoeslint"
            className="ml-4 textPrimaryColor btn-link"
          >
            Iniciar tour
          </a>
        </div>
      </section>
      <section
        id="about"
        className="w-100 flex justify-center items-center ph-4 relative"
      >
        <div className="center-content pl-4 pr-4 pb-4 relative textPrimaryColor">
          <Title value="Vamos lá..." />
          <div className="configText">
            <p>
              Como programador front-end venho me especializando há mais de 5 anos
              no mercado em e-commerce, criando interfaces funcionais para
              plataformas de comércio eletrônico. Utilizando tecnologias como
              HTML, CSS e JavaScript, trabalho na implementação de layouts
              responsivos e na integração de recursos interativos, garantindo uma
              experiência de compra intuitiva para os usuários. Tenho experiência
              em frameworks como React, buscando sempre otimizar o desempenho do
              site e garantir <span className="primaryColor">acessibilidade</span>
              , <span className="primaryColor">performance</span> e uma boa{" "}
              <span className="primaryColor">visibilidade</span> nos mecanismos de
              busca através das melhores práticas de{" "}
              <span className="primaryColor">SEO</span>.
            </p>
          </div>
        </div>
      </section>
      <section
        id="skills"
        className="w-100 flex justify-center items-center ph-4 relative"
      >
        <div className="center-content pl-4 pr-4 pb-4 relative textPrimaryColor">
          <Title value="Experiência" />
          <div className="configText">
            <ul id="list-skill" className="flex flex-wrap">
              <li className="mv-4">
                <img src={EcommerceIcon} alt="Loja Virtual" />
                <div className="info_text">
                  <h3>Loja Virtual</h3>
                  <span>
                    Crio, personalizo e customizo sua loja na plataforma VTEX,
                    todo layout é aplicado com técnicas avanças de SEO para
                    facilitar a navegação tanto para Mobile quanto Desktop.
                  </span>
                </div>
              </li>
              <li className="mv-4">
                <img src={SEOIcon} alt="Análise SEO" />
                <div className="info_text">
                  <h3>Analise SEO</h3>
                  <span>
                    Além de desenvolvedor também sou Analista SEO, analiso a
                    performance de seu site tanto no conteúdo quanto no código
                    para atingir melhores resultados no Google e no tempo de
                    carregamento da página.
                  </span>
                </div>
              </li>
              <li className="mv-4">
                <img src={blogIcon} alt="Alarcon Press" />
                <div className="info_text">
                  <h3>Alarcon Press</h3>
                  <span>
                    Assim como criar uma loja virtual, criar um site ou blog
                    requer diversas análises da concorrência, público alvo,
                    tendências e afins. por isso com a Alarcon Press, você
                    tudo que precisa, com a performance, segurança e
                    ferramentas feitas para você!
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section
        id="projects"
        className="w-100 flex justify-center items-center ph-4 relative"
      >
        <div className="center-content pl-4 pr-4 pb-4 relative textPrimaryColor">
          <Title value="Portfólio" />
        </div>
      </section>
      <section
        id="contact"
        className="w-100 flex justify-center items-center pa-4 relative"
      >
        <div id="contact-bg" className="bg-second"></div>
        <div className="center-content flex flex-wrap pa-4 relative textPrimaryColor justify-between">
          <div className="flex items-center">
            <ul className="info-contact">
              <li className="flex items-center">
                <IconWhatsApp height="34px" fill="#f3c100" class="mr-1" />
                <div>
                  <span>Ligue para nós</span>
                  <span>(11) 9 7512-3488</span>
                </div>
              </li>
              <li className="flex items-center">
                <IconLocalization height="40px" fill="#f3c100" class="mr-1" />
                <div>
                  <span>Localização</span>
                  <span>Poá, SP - Brasil.</span>
                </div>
              </li>
              <li className="flex items-center">
                <IconTime height="30px" fill="#f3c100" class="mr-1" />
                <div>
                  <span>Horário comercial</span>
                  <span>Seg a Sexta das 9h00 às 18h00.</span>
                </div>
              </li>
            </ul>
          </div>
          <div className="box">
            <Title value="Vamos conversar?" />
            <form onSubmit={HandleClick}>
              <input
                type="text"
                value={dataForm.name}
                onChange={(e) =>
                  setDataForm({ ...dataForm, name: e.target.value })
                }
                placeholder="Como deseja ser chamado?"
                required
              />
              <input
                type="text"
                value={dataForm.subject}
                onChange={(e) =>
                  setDataForm({ ...dataForm, subject: e.target.value })
                }
                placeholder="Qual o assunto?"
                required
              />
              <textarea
                placeholder="Pode falar..."
                value={dataForm.message}
                onChange={(e) =>
                  setDataForm({ ...dataForm, message: e.target.value })
                }
                required
              />
              <button type="submit">Enviar</button>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};

export default MainHome;
