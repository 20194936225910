import React from "react";
import "./App.css";
import Header from "./subtemplates/header/header";
import MainHome from "./pages/Home/Home";
import Footer from "./subtemplates/footer/footer";

function App() {
  const [heightHeader, setHeightHeader] = React.useState<string>("");
  React.useEffect(() => {
    setHeightHeader(document.querySelector(".mainHeader")?.scrollHeight + "px");
    document.body.classList.add("bg-primary");
    document.body.style.paddingTop = heightHeader;
  });
  return (
    <div className="App">
      <Header />
      <MainHome height={heightHeader} />
      <Footer />
    </div>
  );
}

export default App;
