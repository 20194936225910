interface Props {
  height?: string;
  width?: string;
  class?: string;
  fill?: string;
}

const IconWhatsApp = (props: Props) => (
  <svg
    id="IconWhatsApp"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 307.2 307.2"
    height={props.height}
    width={props.width}
    className={props.class}
    fill={props.fill}
  >
    <path d="m5.4,307.2H0c0-1.8,0-3.6,0-5.4.25-.53.54-1.04.73-1.59,8.2-24.15,16.41-48.29,24.5-72.48.43-1.3.27-3.21-.39-4.41-9.98-17.91-16.17-36.99-18.45-57.39-1.77-15.84-1.05-31.53,2.3-47.06,6.13-28.42,19.4-53.08,39.87-73.75C71.13,22.33,98.24,8.01,129.86,2.4,135.54,1.4,141.29.79,147,0,153.2,0,159.4,0,165.6,0c.97.19,1.93.41,2.91.56,6.51.95,13.09,1.52,19.52,2.88,28.46,5.99,53.16,19.24,73.92,39.56,23.79,23.29,38.41,51.43,43.51,84.41.67,4.32,1.17,8.67,1.75,13v18.6c-.64,4.94-1.09,9.91-1.95,14.81-6.73,38.33-25.35,69.66-55.93,93.69-24.99,19.65-53.61,30.16-85.31,31.78-26.74,1.37-52.21-4-76.15-16.16-2.01-1.02-3.76-1.25-5.94-.54-16.03,5.19-32.1,10.24-48.14,15.39-9.48,3.04-18.93,6.15-28.39,9.23Zm41.9-46.46c1.26-.33,1.93-.48,2.59-.69,11.88-3.76,23.76-7.48,35.61-11.32,2.39-.78,4.37-.56,6.5.78,16.48,10.43,34.4,16.53,53.87,18.24,24.18,2.13,46.93-2.71,68.11-14.32,22.94-12.58,40.03-30.88,51.02-54.69,9.71-21.03,13.01-43.12,9.54-66.01-3.65-24.12-13.8-45.25-30.48-63.17-15.42-16.56-34.03-27.84-55.79-33.94-22.73-6.38-45.48-6.04-67.88,1.16-28.98,9.31-51.58,27.06-67.14,53.37-14.02,23.71-18.97,49.34-15.13,76.59,2.58,18.29,9.23,35.05,19.93,50.12,2.08,2.92,2.36,5.6,1.19,8.93-4.04,11.45-7.86,22.98-11.94,34.96Z" />
    <path d="m197.6,222.58c-7.75-1.72-15.62-3.05-23.24-5.24-17.89-5.12-34-13.7-48.03-26.04-12.84-11.3-23.51-24.46-33.03-38.61-5.8-8.62-10.42-17.79-11.93-28.14-1.76-12.03.15-23.42,7.14-33.72,4.47-6.59,11.1-9.11,18.49-10.38,2.06-.35,4.15-.6,6.23-.84,5.07-.58,8.78,1,10.85,5.59,4.63,10.27,8.94,20.68,13.28,31.07,1.19,2.85.82,5.85-.86,8.34-2.9,4.28-6.08,8.37-9.26,12.45-.95,1.22-1.56,2.07-.46,3.6,11.44,15.99,25.07,29.52,42.97,38.23,5.81,2.83,5.89,2.81,9.94-2,2.77-3.28,5.39-6.69,8.08-10.04,3.31-4.14,6.36-5.18,11.33-3.48,2.72.93,5.33,2.23,7.94,3.47,7.56,3.6,15.09,7.28,22.65,10.88,3.66,1.74,5.11,4.56,5.26,8.6.83,22.5-18.08,33.86-33.55,35.11-1.2.1-2.39.16-3.59.24-.06.3-.13.6-.19.9Z" />
  </svg>
);

export default IconWhatsApp;
