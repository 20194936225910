
const MenuItems = () => (
  <>
    <div className="navCol-menuItems--content">
      <ul className="flex items-center pa-1">
        <li className="pa-1">
          <a className="textPrimaryColor" href="/#about">
            Sobre
          </a>
        </li>
        <li className="pa-1">
          <a className="textPrimaryColor" href="/#skills">
            Experiência
          </a>
        </li>
        <li className="pa-1">
          <a className="textPrimaryColor" href="/#projects">
            Trabalhos
          </a>
        </li>
        <li className="pa-1">
          <a
            className="textPrimaryColor"
            title="Anota aí: (11) 9 7512-3488"
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send/?phone=5511975123488"
          >
            Whatsapp
          </a>
        </li>
        <li className="last-item">
          <a
            target="_blank"
            rel="noreferrer"
            href={'/documentos/VINICIUS_GARCIA_ALARCON_-_Desenvolvedor_VTEX.pdf'}
            className="pa-1 primaryColor"
          >
            Resumo.pdf
          </a>
        </li>
      </ul>
    </div>
  </>
);

export default MenuItems;
