import React from "react";
// import logo from "../../assets/images/logo.svg";
import "./header.scss";
import MenuItems from "./components/MenuItems";
import IconMenuApp from "../../assets/images/icons/icon-menuApp";

const Header = () => {
  const [isAcessibility, setIsAcessibility] = React.useState<string>("");
  const [showMenu, setShowMenu] = React.useState<boolean>(
    window.innerWidth >= 768 ? true : false
  );

  const AcessibilityMode = () => {
    document.body.classList.toggle("lightMode");
    setIsAcessibility(
      isAcessibility === "reactjs-active" ? "" : "reactjs-active"
    );
  };

  return (
    <header className="mainHeader">
      <nav className="flex pa-1 justify-between">
        <div className="logo flex items-center navCol-left" tabIndex={-1}>
          <span className="logo pa-1 pl-3rem flex textPrimaryColor">
            <span className="primaryColor">&lt;</span>V
            <span className="hide">inícius</span> A
            <span className="hide">larcon</span>
            <span className="primaryColor">&gt;</span>
          </span>
        </div>
        {showMenu && (
          <div className="navCol-menuItems">
            {window.innerWidth <= 768 && (
              <div
                className="overlay"
                onClick={() => setShowMenu(!showMenu)}
              ></div>
            )}
            <MenuItems />
          </div>
        )}
        <div className="flex items-center justify-end pr-1 navCol-right">
          {window.innerWidth <= 768 && (
            <div
              className="flex items-center menu-nav relative cursor-pointer mh-1"
              onClick={() => setShowMenu(!showMenu)}
            >
              <span className="hamburguer-icon mr-10px"></span>
            </div>
          )}
          <div
            typeof="button"
            onClick={() => AcessibilityMode()}
            className={`acessibility-btn ${isAcessibility}`}
          >
            <div className="icon-button"></div>
          </div>
          {window.innerWidth >= 768 && (
            <div className="flex items-center menu-apps ml-1 relative cursor-pointer">
              <IconMenuApp />
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
