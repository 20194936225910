interface Props {
  height?: string;
  width?: string;
  class?: string;
  fill?: string;
}

const IconLocalization = (props: Props) => (
  <svg
    id="IconLocalization"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 216.88 320.25"
    height={props.height}
    width={props.width}
    className={props.class}
    fill={props.fill}
  >
    <path d="m104.78,320.25c-3.68-6.18-6.91-11.55-10.09-16.94-20.95-35.51-42.18-70.85-62.72-106.6-12.84-22.36-23.23-45.89-29.47-71.08-5.28-21.3-1.86-41.75,7.08-61.18C25.94,28.9,54.1,8.12,92.5,1.42c48.47-8.46,99.94,21.68,117.47,67.45,11.14,29.1,8.22,56.7-4.51,84.4-11.44,24.9-26.54,47.6-40.87,70.78-19.66,31.79-39.47,63.49-59.81,96.19Zm3.44-152.68c34.32,0,62.51-28.12,62.36-62.22-.14-33.97-28.18-62.03-62.07-62.12-34.3-.09-62.38,27.8-62.49,62.07-.12,34.3,27.81,62.26,62.2,62.27Z" />
  </svg>
);

export default IconLocalization;
