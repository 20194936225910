interface Props {
  height?: string;
  width?: string;
  class?: string;
  fill?: string;
}

const IconTime = (props: Props) => (
  <svg
    id="IconTime"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 347.18 313.1"
    height={props.height}
    width={props.width}
    className={props.class}
    fill={props.fill}
  >
    <path d="m347.18,147.48c-29.11-16.08-57.3-31.66-86.03-47.54,9.16-5.54,17.8-10.78,26.76-16.2-8.59-11.16-18.77-20.12-29.96-28C171.13-5.37,47.88,46.83,31.74,151.84c-7.82,50.9,7.82,94.31,44.58,130.19,7.34,7.16,7.57,8.41,1.13,15.98-2.26,2.66-4.66,5.22-7.13,7.69-2.99,2.99-8.01,2.59-12-.97-28.49-25.47-47.33-56.82-54.52-94.31-12.63-65.86,6.15-122.21,55.76-167.05C93.71,12.5,134.96-1.45,181.16.12c48.17,1.63,88.53,20.66,121.92,55.03,3.81,3.92,7.23,8.23,11.16,12.73,10.23-6.09,20.42-12.16,31.26-18.6.57,32.92,1.12,65,1.69,98.21Z" />
    <path d="m151.98,178.81c-2.7-1.06-4.63-1.6-6.34-2.53-23.09-12.63-46.16-25.29-69.2-38-5.65-3.12-7.37-8.25-4.64-13.09,2.57-4.55,7.78-5.75,13.26-2.77,20.42,11.09,40.83,22.2,61.13,33.49,2.94,1.63,4.6,1.44,7.07-.86,24-22.36,48.14-44.57,72.25-66.81,4.53-4.18,10.32-4.33,13.96-.46,3.79,4.03,3.26,9.63-1.33,13.86-26.27,24.21-52.52,48.45-78.88,72.57-2.14,1.96-4.97,3.16-7.29,4.6Z" />
    <path d="m161.82,313.02c9.75-11.58,18.91-22.46,28.07-33.34,6.34-7.52,12.86-14.89,18.94-22.6,2.71-3.44,5.13-7.35,6.67-11.43,2.48-6.54-.99-13.47-7.01-15.49-7.15-2.41-14.53.81-16.9,7.64-1.02,2.93-1.39,6.09-2.09,9.28h-23.75c-2.83-15,7.35-31.21,22.59-36.11,10.36-3.33,20.9-3.27,31.22,0,18.64,5.9,27.73,25.56,19.18,43.4-3.76,7.84-10.07,14.51-15.58,21.43-4.12,5.17-8.84,9.87-14.11,15.69h31.61v21.53h-78.85Z" />
    <path d="m316.85,269.88h11.29v20.01c-3.3.29-6.57.57-10.77.94v22.27h-24v-22.4h-47c.2-10.52-1.91-20,4.79-29.26,10.86-15,20.5-30.9,30.48-46.53,2.13-3.33,4.5-4.7,8.54-4.54,8.67.33,17.37.1,26.68.1v59.42Zm-23.84-38.05c-8.5,13.01-16.32,25-24.47,37.47h24.47v-37.47Z" />
  </svg>
);

export default IconTime;
